/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { SxProps, Theme, alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import TranslateIcon from '@mui/icons-material/Translate';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import HomeIcon from '@mui/icons-material/Home';
import CableIcon from '@mui/icons-material/Cable';
import LibraryBooks from '@mui/icons-material/LibraryBooks';

const roundListItemButtonSx: SxProps<Theme> = {
  minHeight: 44,
  borderRadius: 1.5,
  typography: 'body2',
  color: 'text.secondary',
  textTransform: 'capitalize',
  fontWeight: 'fontWeightMedium',
  // color: 'primary.main',
  // fontWeight: 'fontWeightSemiBold',
  // bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
  '&:hover': {
    bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
  },
};

export const MainListItems = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <ListItemButton sx={roundListItemButtonSx} onClick={() => navigate('/')}>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItemButton>
      <ListItemButton
        sx={roundListItemButtonSx}
        onClick={() => navigate('/articles')}
      >
        <ListItemIcon>
          <HistoryEduIcon />
        </ListItemIcon>
        <ListItemText primary="Medical Writer" />
      </ListItemButton>
      <ListItemButton
        sx={roundListItemButtonSx}
        onClick={() => navigate('/translate-tasks')}
      >
        <ListItemIcon>
          <TranslateIcon />
        </ListItemIcon>
        <ListItemText primary="Medical Translator" />
      </ListItemButton>
      <ListItemButton
        sx={roundListItemButtonSx}
        onClick={() => navigate('/glossary')}
      >
        <ListItemIcon>
          <LocalLibraryIcon />
        </ListItemIcon>
        <ListItemText primary="Glossary" />
      </ListItemButton>
      <ListItemButton
        sx={roundListItemButtonSx}
        onClick={() => navigate('/reference-store')}
      >
        <ListItemIcon>
          <LibraryBooks />
        </ListItemIcon>
        <ListItemText primary="Reference Store" />
      </ListItemButton>
      <ListItemButton
        sx={roundListItemButtonSx}
        onClick={() => navigate('/settings/connectors')}
      >
        <ListItemIcon>
          <CableIcon />
        </ListItemIcon>
        <ListItemText primary="Connectors" />
      </ListItemButton>
    </React.Fragment>
  );
};

/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import axios, { isAxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  Configuration,
  GrantTokenDtoGrantTypeEnum,
  GrantTokenResponseDto,
  OidcApi,
} from '@ink-ai/insight-service-sdk';
import { getStore } from '../reducers';
import { auth } from '../reducers/auth';
import { correction } from '../reducers/correction';

let refreshTokenPromise:
  | Promise<AxiosResponse<GrantTokenResponseDto, any>>
  | undefined;

export const getAccessToken = async (type: 'access' | 'id' = 'access') => {
  const store = getStore();
  const { accessToken, expiresAt, refreshToken, idToken } =
    store.getState().auth;
  const token = type === 'access' ? accessToken : idToken;
  // refresh token 1 minute before expire
  if (token && (expiresAt ?? 0) * 1000 > Date.now() + 1000 * 60) {
    return token;
  }
  if (!refreshToken) {
    throw Error('No refresh token available');
  }
  console.log('AccessToken expired, start refreshing');
  if (refreshTokenPromise === undefined) {
    refreshTokenPromise = getOidcApi().grantToken({
      grant_type: GrantTokenDtoGrantTypeEnum.RefreshToken,
      refresh_token: refreshToken,
    });
  }
  try {
    const res = await refreshTokenPromise;
    store.dispatch(auth.actions.tokenGranted(res.data));
    return type === 'access' ? res.data.access_token : res.data.id_token;
  } catch (error) {
    console.log('Refresh token failed');
    console.error(error);
    if (isAxiosError(error) && error.status === 401) {
      store.dispatch(auth.actions.signOut());
    }
    throw error;
  } finally {
    refreshTokenPromise = undefined;
  }
};

export const getApiConfig = async (): Promise<Configuration> => {
  return new Configuration({
    accessToken: await getAccessToken('id'),
    basePath: getStore().getState().app.appParam.ink_ai_api_endpoint,
  });
};

export const getOidcApiConfig = (): Configuration => {
  return new Configuration({
    basePath: `${
      getStore().getState().app.appParam.ink_ai_api_endpoint
    }${'/public'}`,
  });
};

export const getApi = async <T>(
  Api: new (conf: Configuration) => T,
): Promise<T> => {
  return new Api(await getApiConfig());
};

export const getOidcApi = () => {
  return new OidcApi(getOidcApiConfig());
};

export const makeRequest = async <T = any, D = any>(
  options: AxiosRequestConfig<D>,
) => {
  const store = getStore();
  try {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${store.getState().auth.accessToken}`,
    };
    options.baseURL = getStore().getState().app.appParam.ink_ai_api_endpoint;
    const reps = await axios<T>(options);
    return reps.data;
  } catch (error) {
    console.error(error);
    if (axios.isAxiosError(error)) {
      if (error.code === 'ERR_NETWORK') {
        store.dispatch(auth.actions.signOut());
        store.dispatch(correction.actions.clearAll());
      }
    }
    throw error;
  }
};

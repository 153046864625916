/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getApi } from '../common/requestHelper';
import {
  CreateReferenceStoreDto,
  CreateReferenceStoreResponseDtoStatusEnum,
  ReferenceStoreApi,
  ReferenceStoreDto,
} from '@ink-ai/insight-service-sdk';
import { app } from './app';

export enum FileUploadStatus {
  WaitingUpload = 'Waiting upload',
  Complete = 'Complete',
  Error = 'Error',
  Uploading = 'Uploading',
  Importing = 'Importing',
}

export const statusStringMapping: Record<
  CreateReferenceStoreResponseDtoStatusEnum,
  string
> = {
  [CreateReferenceStoreResponseDtoStatusEnum.Importing]: 'Importing',
  [CreateReferenceStoreResponseDtoStatusEnum.Ready]: 'Ready',
  [CreateReferenceStoreResponseDtoStatusEnum.Error]: 'Failed',
};

export const statusClassMapping: Record<
  CreateReferenceStoreResponseDtoStatusEnum,
  string
> = {
  [CreateReferenceStoreResponseDtoStatusEnum.Importing]: 'text-blue-600',
  [CreateReferenceStoreResponseDtoStatusEnum.Ready]: 'text-green-600',
  [CreateReferenceStoreResponseDtoStatusEnum.Error]: 'text-red-600',
};

export type ReferenceStoreState = {
  referenceStores: ReferenceStoreDto[];
  loading: boolean;
  selectedReferenceStore: ReferenceStoreDto | null;
  FileUploadStatus?: FileUploadStatus | null;
  progress: number;
};

const initialState: ReferenceStoreState = {
  referenceStores: [],
  loading: false,
  selectedReferenceStore: null,
  FileUploadStatus: FileUploadStatus.WaitingUpload,
  progress: 0,
};

export const handleDownload = async (id: string) => {
  try {
    const referenceStoreApi = await getApi(ReferenceStoreApi);
    const response = await referenceStoreApi.getReferenceStoreDownloadLink(id);
    const a = document.createElement('a');
    a.href = response.data.downloadLink;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (error) {
    console.error('Failed to download the reference store:', error);
  }
};

export const listReferenceStores = createAsyncThunk<ReferenceStoreDto[]>(
  'referenceStore/listReferenceStores',
  async () => {
    const referenceStoreApi = await getApi(ReferenceStoreApi);
    const response = await referenceStoreApi.listReferenceStores();
    return response.data.referenceStores;
  },
);

export const getReferenceStoreById = createAsyncThunk(
  'referenceStore/getReferenceStoreById',
  async (uuid: string) => {
    const referenceStoreApi = await getApi(ReferenceStoreApi);
    const response = await referenceStoreApi.getReferenceStoreById(uuid);
    return response.data;
  },
);

export const deleteReferenceStores = createAsyncThunk(
  'referenceStore/deleteReferenceStores',
  async (referenceStoreIds: string[], { getState, dispatch }) => {
    const state = getState() as { referenceStore: ReferenceStoreState };
    const referenceStoreApi = await getApi(ReferenceStoreApi);

    const deletePromises = referenceStoreIds.map(async (uuid) => {
      const selectedReferenceStore = state.referenceStore.referenceStores.find(
        (g) => g.uuid === uuid,
      );
      if (selectedReferenceStore) {
        await referenceStoreApi.deleteReferenceStore(
          selectedReferenceStore.uuid,
        );
      }
    });

    await Promise.all(deletePromises);
    dispatch(listReferenceStores());
  },
);

export const importReferenceStore = createAsyncThunk(
  'referenceStore/importReferenceStore',
  async (
    {
      name,
      description,
      uuid,
      uploadTaskId,
    }: {
      name: string;
      description: string;
      uploadTaskId: string;
      uuid?: string;
    },
    { dispatch },
  ) => {
    const referenceStoreApi = await getApi(ReferenceStoreApi);
    if (uuid) {
      const newReferenceStore: CreateReferenceStoreDto = {
        name: name,
        desc: description,
        uploadTaskId,
      };
      await referenceStoreApi.updateUploadReferenceStore(
        uuid,
        newReferenceStore,
      );
    } else {
      await referenceStoreApi.createReferenceStore({
        name,
        desc: description,
        uploadTaskId,
      });
    }
    dispatch(
      referenceStoreActions.setFileUploadStatus({
        status: FileUploadStatus.Importing,
      }),
    );
    dispatch(app.actions.setNavigationPath('/reference-store'));
  },
);

export const referenceStore = createSlice({
  name: 'referenceStore',
  initialState,
  reducers: {
    setLoading(state, { payload }: PayloadAction<boolean>) {
      state.loading = payload;
      console.log('state.loading: ', state.loading);
    },
    setSelectedReferenceStore(
      state,
      { payload }: PayloadAction<ReferenceStoreDto | null>,
    ) {
      state.selectedReferenceStore = payload;
    },
    setProgress(state, action: PayloadAction<number>) {
      state.progress = action.payload;
    },
    setFileUploadStatus(
      state,
      {
        payload,
      }: PayloadAction<{
        status: FileUploadStatus | null;
      }>,
    ) {
      state.FileUploadStatus = payload.status;
    },
    clearAll: () => {
      return {
        ...initialState,
        timestamp: Date.now(),
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listReferenceStores.pending, (state) => {
        state.loading = true;
      })
      .addCase(listReferenceStores.fulfilled, (state, action) => {
        state.referenceStores = action.payload;
        state.loading = false;
      })
      .addCase(listReferenceStores.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getReferenceStoreById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReferenceStoreById.fulfilled, (state, action) => {
        state.selectedReferenceStore = action.payload;
        state.loading = false;
      })
      .addCase(getReferenceStoreById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(importReferenceStore.pending, (state) => {
        state.loading = true;
      })
      .addCase(importReferenceStore.fulfilled, (state) => {
        state.loading = true; // it is still in importing state
        state.progress = 0;
      })
      .addCase(importReferenceStore.rejected, (state) => {
        state.loading = false;
        state.FileUploadStatus = FileUploadStatus.Error;
        state.progress = 0;
      });
  },
});

export const referenceStoreActions = referenceStore.actions;
export default referenceStore.reducer;

/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosProgressEvent } from 'axios';
import { getApi } from '../common/requestHelper';
import {
  CreateExternalGlossaryCollectionResponseDtoStatusEnum,
  CreateExternalGlossaryDto,
  CreateGlossaryDto,
  CreateGlossaryItemDto,
  GlossaryApi,
  GlossaryCollectionDto,
  GlossaryItemDto,
  GlossaryItemResponseDto,
  UpdateGlossaryItemDto,
} from '@ink-ai/insight-service-sdk';
import Papa from 'papaparse';
import { app } from './app';
import { RootState } from '.';

export type GlossaryItemTemplateDto = Omit<
  GlossaryItemResponseDto,
  'id' | 'uuid' | 'parentId' | 'createdAt' | 'updatedAt'
>;

export enum ImportFileParseStatus {
  WaitingUpload = 'Waiting upload',
  Complete = 'Complete',
  Error = 'Error',
  Uploading = 'Uploading',
  Importing = 'Importing',
}

export const statusStringMapping: Record<
  CreateExternalGlossaryCollectionResponseDtoStatusEnum,
  string
> = {
  [CreateExternalGlossaryCollectionResponseDtoStatusEnum.PendingUpload]:
    'Pending Upload',
  [CreateExternalGlossaryCollectionResponseDtoStatusEnum.Importing]:
    'Importing',
  [CreateExternalGlossaryCollectionResponseDtoStatusEnum.Ready]: 'Ready',
  [CreateExternalGlossaryCollectionResponseDtoStatusEnum.Error]: 'Failed',
};

export const statusClassMapping: Record<
  CreateExternalGlossaryCollectionResponseDtoStatusEnum,
  string
> = {
  [CreateExternalGlossaryCollectionResponseDtoStatusEnum.PendingUpload]:
    'text-blue-600',
  [CreateExternalGlossaryCollectionResponseDtoStatusEnum.Importing]:
    'text-blue-600',
  [CreateExternalGlossaryCollectionResponseDtoStatusEnum.Ready]:
    'text-green-600',
  [CreateExternalGlossaryCollectionResponseDtoStatusEnum.Error]: 'text-red-600',
};

export type GlossaryState = {
  glossaries: GlossaryCollectionDto[];
  glossaryItems: GlossaryItemResponseDto[];
  loading: boolean;
  selectedGlossary: GlossaryCollectionDto | null;
  importFileParseStatus: ImportFileParseStatus | null;
  parsedFileContent: GlossaryItemTemplateDto[] | null;
  timestamp: number; // Used to tell the action whether the job is outdated
  progress: number;
};

const initialState: GlossaryState = {
  glossaries: [],
  glossaryItems: [],
  loading: false,
  selectedGlossary: null,
  importFileParseStatus: ImportFileParseStatus.WaitingUpload,
  parsedFileContent: null,
  timestamp: 0,
  progress: 0,
};

export const handleDownload = async (id: string) => {
  try {
    const glossaryApi = await getApi(GlossaryApi);
    const response = await glossaryApi.getGlossaryDownloadLink(id);
    const a = document.createElement('a');
    a.href = response.data.downloadLink;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (error) {
    console.error('Failed to download the glossary:', error);
  }
};

export const listGlossaryCollections = createAsyncThunk<
  GlossaryCollectionDto[]
>('glossary/listGlossaryCollections', async () => {
  const glossaryApi = await getApi(GlossaryApi);
  const response =
    await glossaryApi.glossaryControllerListGlossaryCollections();
  return response.data.collections;
});

export const listGlossaryItemsById = createAsyncThunk(
  'glossary/listGlossaryItemsById',
  async (uuid: string) => {
    const glossaryApi = await getApi(GlossaryApi);
    const response =
      await glossaryApi.glossaryControllerListGlossaryItemsById(uuid);
    return response.data.items;
  },
);

export const getGlossaryById = createAsyncThunk(
  'glossary/getGlossaryById',
  async (uuid: string) => {
    const glossaryApi = await getApi(GlossaryApi);
    const response = await glossaryApi.getGlossaryById(uuid);
    return response.data;
  },
);

export const createGlossaryItem = createAsyncThunk(
  'glossary/createGlossaryItem',
  async (
    {
      glossaryUuid,
      item,
    }: { glossaryUuid: string; item: CreateGlossaryItemDto },
    { dispatch },
  ) => {
    const glossaryApi = await getApi(GlossaryApi);
    await glossaryApi.glossaryControllerCreateGlossaryItem(glossaryUuid, item);
    dispatch(listGlossaryItemsById(glossaryUuid));
  },
);

export const updateGlossaryItem = createAsyncThunk(
  'glossary/updateGlossaryItem',
  async (
    {
      glossaryUuid,
      itemUuid,
      item,
    }: {
      glossaryUuid: string;
      itemUuid: string;
      item: UpdateGlossaryItemDto;
    },
    { dispatch },
  ) => {
    const glossaryApi = await getApi(GlossaryApi);
    const response = await glossaryApi.glossaryControllerUpdateGlossaryItem(
      glossaryUuid,
      itemUuid,
      item,
    );
    dispatch(listGlossaryItemsById(glossaryUuid));
    return response.data;
  },
);

export const removeGlossaryItems = createAsyncThunk(
  'glossary/removeGlossaryItems',
  async (
    { glossaryUuid, itemUuids }: { glossaryUuid: string; itemUuids: string[] },
    { getState, dispatch },
  ) => {
    const state = getState() as { glossary: GlossaryState };
    const glossaryApi = await getApi(GlossaryApi);

    const deletePromises = itemUuids.map(async (itemUuid) => {
      const item = state.glossary.glossaryItems.find(
        (g) => g.uuid === itemUuid,
      );
      if (item) {
        await glossaryApi.glossaryControllerRemoveGlossaryItem(
          glossaryUuid,
          item.uuid,
        );
      }
    });

    await Promise.all(deletePromises);
    dispatch(listGlossaryItemsById(glossaryUuid));
  },
);

export const deleteGlossaryCollections = createAsyncThunk(
  'glossary/deleteGlossaryCollections',
  async (glossaryIds: string[], { getState, dispatch }) => {
    const state = getState() as { glossary: GlossaryState };
    const glossaryApi = await getApi(GlossaryApi);

    const deletePromises = glossaryIds.map(async (uuid) => {
      const selectedGlossary = state.glossary.glossaries.find(
        (g) => g.uuid === uuid,
      );
      if (selectedGlossary) {
        await glossaryApi.glossaryControllerDeleteGlossaryCollection(
          selectedGlossary.uuid,
        );
      }
    });

    await Promise.all(deletePromises);
    dispatch(listGlossaryCollections());
  },
);

export const createGlossaryFromParsedFile = createAsyncThunk(
  'glossary/createGlossaryFromParsedFile',
  async (
    { name, description }: { name: string; description: string },
    { getState, dispatch },
  ) => {
    try {
      const state = getState() as { glossary: GlossaryState };
      const parsedFileContent = state.glossary.parsedFileContent;

      if (!parsedFileContent) {
        throw new Error('No parsed file content available');
      }

      const items: GlossaryItemDto[] = parsedFileContent.map((item: any) => {
        const { translations, ...rest } = item;
        return {
          ...rest,
          trans: translations,
        };
      });

      const newGlossary: CreateGlossaryDto = {
        name,
        desc: description,
        items: items,
      };

      const glossaryApi = await getApi(GlossaryApi);
      await glossaryApi.glossaryControllerCreateGlossaryCollection(newGlossary);
      dispatch(
        app.actions.setGlobalMessage({
          message: 'Glossary imported successfully.',
          status: 'success',
        }),
      );
      dispatch(app.actions.setNavigationPath('/glossary'));
    } catch (error) {
      dispatch(
        app.actions.setGlobalMessage({
          message:
            error.message || 'Failed to create glossary from parsed file',
          status: 'error',
        }),
      );
      throw new Error(
        error.message || 'Failed to create glossary from parsed file',
      );
    }
  },
);

export const importExternalGlossary = createAsyncThunk(
  'glossary/importExternalGlossary',
  async (
    {
      name,
      description,
      file,
      uuid,
    }: { name: string; description: string; file: File; uuid?: string },
    { getState, dispatch },
  ) => {
    const state = getState() as RootState;
    const initTimestamp = state.glossary.timestamp;
    // Get the presigned URL
    dispatch(
      glossaryActions.setImportFileParseStatus({
        status: ImportFileParseStatus.Uploading,
        content: null,
      }),
    );
    const glossaryApi = await getApi(GlossaryApi);
    let response;
    if (uuid) {
      const newExternalGlossary: CreateExternalGlossaryDto = {
        name: name,
        desc: description,
      };
      response = await glossaryApi.updateForUploadGlossary(
        uuid,
        newExternalGlossary,
      );
    } else {
      response = await glossaryApi.createForUploadGlossary({
        name,
        desc: description,
      });
    }
    const { uploadUrl, uuid: responseUuid } = response.data;

    // Upload the file to S3
    try {
      await axios.put(uploadUrl, file, {
        headers: {
          'Content-Type': file.type || 'application/octet-stream',
        },
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / (progressEvent.total || 1),
          );
          const currState = getState() as RootState;
          if (initTimestamp !== currState.glossary.timestamp) {
            return;
          }
          dispatch(glossaryActions.setProgress(progress));
        },
      });
    } catch (error) {
      console.error('Error uploading file to S3:', error);
    }

    // Start the ingestion
    const currState = getState() as RootState;
    if (initTimestamp !== currState.glossary.timestamp) {
      console.warn('The job is outdated, aborting');
      return;
    }
    dispatch(
      glossaryActions.setImportFileParseStatus({
        status: ImportFileParseStatus.Importing,
        content: null,
      }),
    ),
      await glossaryApi.startImport(responseUuid);
    dispatch(app.actions.setNavigationPath('/glossary'));
  },
);

export const updateGlossaryFromParsedFile = createAsyncThunk(
  'glossary/updateGlossaryFromParsedFile',
  async (
    {
      glossaryUuid,
      name,
      description,
    }: { glossaryUuid: string; name: string; description: string },
    { getState, dispatch },
  ) => {
    try {
      const state = getState() as { glossary: GlossaryState };
      const parsedFileContent = state.glossary.parsedFileContent;

      if (!parsedFileContent) {
        throw new Error('No parsed file content available');
      }

      const items: GlossaryItemDto[] = parsedFileContent.map((item) => {
        const { translations, ...rest } = item;
        return {
          ...rest,
          trans: translations,
        };
      });

      const newGlossary: CreateGlossaryDto = {
        name: name,
        desc: description,
        items: items,
      };

      const glossaryApi = await getApi(GlossaryApi);
      await glossaryApi.glossaryControllerUpdateGlossaryCollection(
        glossaryUuid,
        newGlossary,
      );

      dispatch(
        app.actions.setGlobalMessage({
          message: 'Glossary updated successfully.',
          status: 'success',
        }),
      );
      dispatch(app.actions.setNavigationPath('/glossary'));
      return newGlossary;
    } catch (error) {
      dispatch(
        app.actions.setGlobalMessage({
          message:
            error.message || 'Failed to update glossary from parsed file',
          status: 'error',
        }),
      );
      throw error;
    }
  },
);

export const exportGlossaryItemsToCsv = createAsyncThunk(
  'glossary/exportGlossaryItemsToCsv',
  async (glossaryId: string, { getState }) => {
    const state = getState() as { glossary: GlossaryState };
    const selectedGlossary = state.glossary.glossaries.find(
      (g) => g.uuid === glossaryId,
    );
    if (selectedGlossary) {
      const glossaryApi = await getApi(GlossaryApi);
      const response =
        await glossaryApi.glossaryControllerListGlossaryItemsById(
          selectedGlossary.uuid,
        );
      const glossaryItems = response.data.items;

      const exportData = glossaryItems.map((item) => {
        const { name, desc, abbr, translations } = item;
        const translationColumns = translations.reduce((acc, translate) => {
          acc[translate.language] = translate.translation;
          return acc;
        }, {});
        return { name, desc, abbr, ...translationColumns };
      });

      const csvHeaders = [
        'name',
        'desc',
        'abbr',
        ...Array.from(
          new Set(
            glossaryItems.flatMap((item) =>
              item.translations.map((translate) => translate.language),
            ),
          ),
        ),
      ];

      const csvData = [
        csvHeaders,
        ...exportData.map((row) =>
          csvHeaders.map((header) => row[header] || ''),
        ),
      ];

      const csv = Papa.unparse(csvData, {
        quotes: true,
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ',',
        newline: '\n',
      });

      const downloadExportCsv = () => {
        const element = document.createElement('a');
        const file = new Blob(['\uFEFF' + csv], {
          type: 'text/csv;charset=utf-8;',
        });
        element.href = URL.createObjectURL(file);
        element.download = `${selectedGlossary.name}.csv`;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      };
      downloadExportCsv();
    }
  },
);

export const exportGlossaryItemsToJSON = createAsyncThunk(
  'glossary/exportGlossaryItemsToJSON',
  async (glossaryId: string, { getState }) => {
    const state = getState() as { glossary: GlossaryState };
    const selectedGlossary = state.glossary.glossaries.find(
      (g) => g.uuid === glossaryId,
    );
    if (selectedGlossary) {
      const glossaryApi = await getApi(GlossaryApi);
      const response =
        await glossaryApi.glossaryControllerListGlossaryItemsById(
          selectedGlossary.uuid,
        );
      const exportData = response.data.items.map((item) => {
        const { name, desc, abbr, translations } = item;
        const filteredTranslations = translations.map((translate) => ({
          language: translate.language,
          translation: translate.translation,
        }));
        return { name, desc, abbr, translations: filteredTranslations };
      });

      const downloadExportJson = () => {
        const element = document.createElement('a');
        const file = new Blob([JSON.stringify(exportData, null, 2)], {
          type: 'application/json',
        });
        element.href = URL.createObjectURL(file);
        element.download = `${selectedGlossary.name}.json`;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      };
      downloadExportJson();
    }
  },
);

export const glossary = createSlice({
  name: 'glossary',
  initialState,
  reducers: {
    setLoading(state, { payload }: PayloadAction<boolean>) {
      state.loading = payload;
      console.log('state.loading: ', state.loading);
    },
    setSelectedGlossary(
      state,
      { payload }: PayloadAction<GlossaryCollectionDto | null>,
    ) {
      state.selectedGlossary = payload;
    },
    setProgress(state, action: PayloadAction<number>) {
      state.progress = action.payload;
    },
    setImportFileParseStatus(
      state,
      {
        payload,
      }: PayloadAction<{
        status: ImportFileParseStatus | null;
        content: GlossaryItemTemplateDto[] | null;
      }>,
    ) {
      state.importFileParseStatus = payload.status;
      state.parsedFileContent = payload.content;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listGlossaryCollections.pending, (state) => {
        state.loading = true;
      })
      .addCase(listGlossaryCollections.fulfilled, (state, action) => {
        state.glossaries = action.payload;
        state.loading = false;
      })
      .addCase(listGlossaryCollections.rejected, (state) => {
        state.loading = false;
      })
      .addCase(listGlossaryItemsById.pending, (state) => {
        state.loading = true;
      })
      .addCase(listGlossaryItemsById.fulfilled, (state, action) => {
        state.glossaryItems = action.payload;
        state.loading = false;
      })
      .addCase(listGlossaryItemsById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getGlossaryById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGlossaryById.fulfilled, (state, action) => {
        state.selectedGlossary = action.payload;
        state.loading = false;
      })
      .addCase(getGlossaryById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateGlossaryItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateGlossaryItem.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateGlossaryItem.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createGlossaryFromParsedFile.pending, (state) => {
        state.loading = true;
      })
      .addCase(createGlossaryFromParsedFile.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createGlossaryFromParsedFile.rejected, (state) => {
        state.loading = false;
      })
      .addCase(importExternalGlossary.pending, (state) => {
        state.loading = true;
      })
      .addCase(importExternalGlossary.fulfilled, (state) => {
        state.loading = false;
        state.progress = 0;
      })
      .addCase(importExternalGlossary.rejected, (state) => {
        state.loading = false;
        state.progress = 0;
      });
  },
});

export const glossaryActions = glossary.actions;
export default glossary.reducer;

/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  sentenceStyleCheckAsync,
  getToolDescription,
} from '@ink-ai/dbrr-toolkit';
import {
  InferenceApi,
  InferenceResponseDto,
  InferenceResponseDtoStatusEnum,
  InferenceResponseDtoTypeEnum,
  LanguageCorrectionItemTypeEnum,
  UserConfigurationDtoLanguageCheckModeEnum,
} from '@ink-ai/insight-service-sdk';
import {
  concurrentConsume,
  getInfAndCorrections,
  SHA256Digest,
} from '../common/utils';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from '.';
import { getApi } from '../common/requestHelper';
import { isWritingStyleConfigChanged } from './configuration';
import { correction } from './correction';
import { splitEvery } from 'ramda';

export type CorrectionItem = InferenceResponseDto & {
  hash: string;
  requestedAt?: number;
  isDiff?: boolean;
};

const initialState = {
  writingStyleList: [] as CorrectionItem[],
  acceptRejectTimestamp: 0,
  loading: false,
  docBarRaiserReviewers: [] as string[],
};

export type WritingStyleState = typeof initialState;

export const getTextHash = (text: string) => SHA256Digest(text);

/**
 * This async thunk is used to analyze the writing style of the text.
 * It takes in an object with two properties: texts and timestamp.
 * It will only be triggered when the writing style configuration is changed.
 */
export const asyncAnalyzeWritingStyle = createAsyncThunk(
  'writingStyle/asyncAnalyze',
  async (
    { texts, timestamp }: { texts: string[]; timestamp: number },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    if (timestamp !== state.correction.acceptRejectTimestamp) {
      throw new Error('Timestamp mismatch, discard stale inference');
    }
    dispatch(correction.actions.startLoading());
    const originalInfList = await Promise.all(
      texts.map(async (text) => ({
        id: uuidv4(),
        hash: await getTextHash(text),
        text,
      })),
    );

    const { infList, corrections } = getInfAndCorrections(
      originalInfList,
      state.writingStyle.writingStyleList,
      InferenceResponseDtoTypeEnum.WritingStyle,
    );

    dispatch(writingStyle.actions.replaceWritingStyleList(corrections));

    const infGroup = splitEvery(5, infList);
    const inferenceApi = await getApi(InferenceApi);
    const scanningMode = state.configuration.languageCheckMode;

    try {
      await concurrentConsume(
        async (
          payload: {
            text: string;
            id: string;
          }[],
        ) => {
          await inferenceApi.textAnalysis({
            payload,
            instanceId: state.auth.instanceId,
            async: true,
          });
          const currentState = getState() as RootState;
          if (
            isWritingStyleConfigChanged(
              state.configuration,
              currentState.configuration,
            )
          ) {
            throw new Error('Inference aborted due to configuration changed!');
          }
          if (!currentState.correction.scanning) {
            // discard all submitting inferences
            dispatch(
              writingStyle.actions.replaceWritingStyleList(
                currentState.writingStyle.writingStyleList.filter(
                  ({ status }) =>
                    status !== InferenceResponseDtoStatusEnum.Submitting,
                ),
              ),
            );
            throw new Error('Inference aborted due to scanning is paused');
          }
        },
        1,
      )(infGroup);
    } finally {
      if (
        scanningMode === UserConfigurationDtoLanguageCheckModeEnum.Selection
      ) {
        // for selection mode, we need automatically pause scanning after inference
        dispatch(correction.actions.setScanning(false));
      }
    }
  },
);

export const newAnalyzeWritingStyle = createAsyncThunk(
  'writingStyle/newAnalyzeWritingStyle',
  async (
    {
      originInf,
      grammarCorrections,
    }: {
      originInf: { id: string; hash: string; text: string }[];
      grammarCorrections: CorrectionItem[];
    },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const inferenceApi = await getApi(InferenceApi);

    const { corrections } = getInfAndCorrections(
      originInf,
      state.writingStyle.writingStyleList,
      InferenceResponseDtoTypeEnum.WritingStyle,
    );
    console.log('corrections for writing style', corrections);
    dispatch(writingStyle.actions.replaceWritingStyleList(corrections));

    // Need to identify corrections with a status of "accept".
    // For these corrections, if their id is already in the writing style list
    // and their status in writing style list is "submitting", then a separate text analysis should be triggered.
    // Collect all text analysis tasks

    const payload = corrections
      .filter(
        (item) =>
          item.status === InferenceResponseDtoStatusEnum.Submitting &&
          grammarCorrections.some(
            (gc) =>
              gc.hash !== item.hash &&
              gc.status !== InferenceResponseDtoStatusEnum.Accepted,
          ),
      )
      .map((existingItem) => ({
        text: existingItem.text,
        id: existingItem.id,
      }));

    if (payload.length > 0) {
      try {
        await inferenceApi.textAnalysis({
          payload,
          instanceId: state.auth.instanceId,
          async: true,
        });
      } catch (error) {
        console.error('Error during text analysis:', error);
      }
    }
  },
);

export const analyzeWritingStyle = createAsyncThunk(
  'writingStyle/analyze',
  async ({ texts }: { texts: string[] }, { dispatch, getState }) => {
    const state = getState() as RootState;

    console.log('analyzeWritingStyle', texts.length);

    try {
      const originalInfList = await Promise.all(
        texts.map(async (text) => ({
          id: uuidv4(),
          hash: await getTextHash(text),
          text,
        })),
      );

      const { infList, corrections } = getInfAndCorrections(
        originalInfList,
        state.writingStyle.writingStyleList,
      );
      dispatch(writingStyle.actions.replaceWritingStyleList(corrections));

      const analyzedResults = await Promise.all(
        infList.map(async (infItem) => {
          const toolResults = await sentenceStyleCheckAsync(
            infItem.text,
            state.configuration.docBarRaiserReviewers,
          );
          const uniqueTypes = new Set();

          const corrections = toolResults.map((result) => {
            uniqueTypes.add(getToolDescription(result.type).name);

            const suggestionsText = Array.isArray(result.suggestions)
              ? result.suggestions.join(', ')
              : '';

            return {
              type: LanguageCorrectionItemTypeEnum.WritingStyle,
              position: result.position,
              length: result.length,
              correction: '',
              correctionType: result.type,
              suggestion: suggestionsText,
            };
          });

          return {
            id: infItem.id,
            text: infItem.text,
            type: InferenceResponseDtoTypeEnum.WritingStyle,
            status: InferenceResponseDtoStatusEnum.Success,
            corrections: corrections,
            createdAt: Date.now(),
            description: Array.from(uniqueTypes).join(', '),
            hash: infItem.hash,
            isDiff: !!corrections.length,
          } as CorrectionItem;
        }),
      );

      console.log('analyzedResults', analyzedResults);

      if (analyzedResults) {
        if (!state.correction.scanning) {
          dispatch(
            writingStyle.actions.replaceWritingStyleList(
              state.writingStyle.writingStyleList.filter(
                ({ status }) =>
                  status !== InferenceResponseDtoStatusEnum.Submitting,
              ),
            ),
          );
          throw new Error('Inference aborted due to scanning is paused');
        }
        dispatch(writingStyle.actions.mergeWritingStyleList(analyzedResults));
      }
    } catch (error) {
      console.error('Error in analyzeWritingStyle:', error);
    }
  },
);

export const writingStyle = createSlice({
  name: 'writingStyle',
  initialState: initialState,
  reducers: {
    updateAcceptRejectTimestamp: (state) => {
      state.acceptRejectTimestamp = Date.now();
    },
    initWritingStyleList: (state, action: PayloadAction<CorrectionItem[]>) => {
      state.writingStyleList = action.payload;
    },
    newAnalyzeWritingStyle: (
      state,
      originInf: PayloadAction<{ id: string; hash: string; text: string }[]>,
    ) => {
      const { corrections } = getInfAndCorrections(
        originInf.payload,
        state.writingStyleList,
        InferenceResponseDtoTypeEnum.WritingStyle,
      );
      console.log('corrections', corrections);
      state.writingStyleList = corrections.map((item) => ({
        ...item,
      }));
    },
    updateWritingStyleList: (
      state,
      { payload }: PayloadAction<Partial<CorrectionItem>>,
    ) => {
      const index = state.writingStyleList.findIndex(
        (item) => item.id === payload.id,
      );
      if (index < 0) {
        return;
      }
      state.writingStyleList = [
        ...state.writingStyleList.slice(0, index),
        {
          ...state.writingStyleList[index],
          ...payload,
          isDiff: !!payload.corrections?.length,
        },
        ...state.writingStyleList.slice(index + 1),
      ];
      console.log('updateWritingStyleList', state.writingStyleList);
    },
    mergeWritingStyleList: (
      state,
      action: PayloadAction<InferenceResponseDto[]>,
    ) => {
      let newWritingStyleList = Array.from(state.writingStyleList);
      let response = Array.from(action.payload);
      newWritingStyleList = newWritingStyleList.map((item) => {
        const index = response.findIndex(({ id }) => id === item.id);
        if (index < 0) {
          return item;
        }
        const newItem = response[index];
        response = response.slice(index + 1);
        return {
          ...item,
          ...newItem,
          isDiff: !!newItem.corrections?.length,
        };
      });
      state.writingStyleList = newWritingStyleList;
    },
    appendWritingStyleList: (
      state,
      action: PayloadAction<CorrectionItem[]>,
    ) => {
      state.writingStyleList = [...state.writingStyleList, ...action.payload];
    },
    replaceWritingStyleList: (
      state,
      action: PayloadAction<CorrectionItem[]>,
    ) => {
      state.writingStyleList = action.payload.map((item) => ({
        ...item,
      }));
    },
    rejectSuggestion: (state, action: PayloadAction<string>) => {
      const inf = state.writingStyleList.find(
        ({ id }) => id === action.payload,
      );
      if (!inf) {
        return;
      }
      inf.status = InferenceResponseDtoStatusEnum.Rejected;
    },
    startLoading: (state) => {
      state.loading = true;
    },
    clearAll: () => {
      return initialState;
    },
  },
});
